/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/images/icon/customer-role/role-1.svg';
import _imports_1 from '@/assets/images/icon/customer-role/role-2.svg';
import _imports_2 from '@/assets/images/icon/customer-role/role-3.svg';
import _imports_3 from '@/assets/images/icon/customer-role/service-loudspeaker.svg';
var _hoisted_1 = {
  class: "customer-type-icon"
};
var _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "",
  srcset: ""
};
var _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "",
  srcset: ""
};
var _hoisted_4 = {
  key: 2,
  src: _imports_2,
  alt: "",
  srcset: ""
};
var _hoisted_5 = {
  key: 3,
  src: _imports_3,
  alt: "",
  srcset: ""
};
export default {
  __name: 'CustomerTypeIcon',
  props: {
    type: [Number, String, Array]
  },
  setup: function setup(__props) {
    var props = __props;
    var customerType = mukiConst.customerType;
    var cData = reactive({
      type: []
    });
    watchEffect(function () {
      if (!Array.isArray(props.type)) {
        cData.type = [props.type];
      } else {
        cData.type = props.type;
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("span", _hoisted_1, [_unref(cData).type.indexOf(_unref(customerType).BIRTHDAY_STAR) > -1 ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true), _unref(cData).type.indexOf(_unref(customerType).NEW) > -1 ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), _unref(cData).type.indexOf(_unref(customerType).ALERT) > -1 ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), _unref(cData).type.indexOf(_unref(customerType).DIFFICULT) > -1 ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : _createCommentVNode("", true)]);
    };
  }
};
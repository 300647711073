/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/PopperPopup.vue';
import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "more-item-list-popup__title"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  class: "more-item-list-popup__item-num"
};
var _hoisted_4 = {
  class: "more-item-list-popup__item-content"
};
import store from '@/store';
export default {
  __name: 'MoreItemListPopup',
  props: {
    show: Boolean,
    target: {
      type: [String, Object]
    },
    title: String,

    /**
     * @property {EventObject} list[0] 事件物件
     */
    list: Array
  },
  emits: ['eventUpdate', 'eventRemove', 'update:show'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      showPopup: false,
      activeItemIdx: '',
      editEventPopupShow: false,
      editPopupTarget: '',

      /**
       * @property {EventObject} activeData 事件物件
       */
      activeData: {}
    });
    var refRoot = ref(null);
    var refList = ref([]);

    function onOk(evt, item, idx) {
      store.commit('setData', {
        editPopupShow: true,
        editPopupTarget: $(evt.target).closest('.more-item-list-popup__item')[0],
        editPopupEventData: item,
        editPopupConfig: {
          backdrop: false
        }
      });
    }

    watch(function () {
      return props.show;
    }, function () {
      if (!props.show) {
        cData.activeItemIdx = '';
      }
    });
    onMounted(function () {});
    return function (_ctx, _cache) {
      var _component_PopperPopup = __unplugin_components_0;

      return _openBlock(), _createBlock(_component_PopperPopup, {
        ref_key: "refRoot",
        ref: refRoot,
        show: __props.show,
        class: "more-item-list-popup",
        target: __props.target,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:show', $event);
        })
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, function (item, idx) {
            return _openBlock(), _createElementBlock("div", {
              key: idx,
              ref_for: true,
              ref: function ref(el) {
                return _unref(refList).push(el);
              },
              class: _normalizeClass(["more-item-list-popup__item", {
                'more-item-list-popup__item_active': _unref(cData).activeItemIdx === idx || null
              }]),
              onClick: function onClick($event) {
                return onOk($event, item, idx);
              }
            }, [_createElementVNode("div", _hoisted_3, _toDisplayString(idx + 1) + ". ", 1), _createElementVNode("div", _hoisted_4, _toDisplayString(item.name) + " 待約 ", 1)], 10, _hoisted_2);
          }), 128))];
        }),
        _: 1
      }, 8, ["show", "target"]);
    };
  }
};